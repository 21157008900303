import { render, staticRenderFns } from "./MainView.vue?vue&type=template&id=3be05a1a&scoped=true"
import script from "./MainView.vue?vue&type=script&lang=js"
export * from "./MainView.vue?vue&type=script&lang=js"
import style0 from "./MainView.vue?vue&type=style&index=0&id=3be05a1a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be05a1a",
  null
  
)

export default component.exports